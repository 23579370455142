<template>
  <v-container>
    <v-card
      color="#FE7103"
      dark
      class="pb-5"
    >
      <v-img
        src="../assets/img/Freespins_bg.webp"
      />
      <v-card-text
        class="text-center pt-0"
      >
        <div
          style="background-image: linear-gradient(to top, #6A1B9A 0%, #AB47BC 100%);"
          class="rounded-pill pa-3"
        >
          <strong class="orange--text">{{ $t('freespins_t1') }}</strong>
          <v-sheet
            color="purple darken-4"
            rounded="pill"
            height="50"
            width="100"
            class="d-flex justify-center align-center mx-auto my-3"
          >
            <strong class="text-h4 font-weight-bold orange--text me-2">{{ today_available }}</strong>
            <small class="pt-3 opacity-5">/ {{ $t('freespins_t2') }}</small>
          </v-sheet>
          <div>
            {{ $t('freespins_t3a') }} <strong>{{ total_available }}</strong> {{ $t('freespins_t3b') }}
          </div>
          <v-divider class="my-2 mx-10 opacity-5" />
          <div class="opacity-5 text-caption">
            <strong>{{ today_available }}</strong> {{ $t('freespins_t4') }}
            <div class="px-10 font-weight-medium">
              {{ $t('freespins_t5') }}
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text
        class="text-center"
      >
        <v-img
          src="../assets/img/pic/freespins01.webp"
          class="rounded-lg"
          @click="enterfree(850)"
        />
        <v-divider class="my-2" />
        <v-img
          src="../assets/img/pic/freespins03.webp"
          class="rounded-lg"
          @click="enterfree(853)"
        />
        <v-divider class="my-2" />
        <v-img
          src="../assets/img/pic/freespins02.webp"
          class="rounded-lg"
          @click="enterfree(852)"
        />
        <v-divider class="my-2" />
        <v-img
          src="../assets/img/pic/freespins04.webp"
          class="rounded-lg"
          @click="enterfree(851)"
        />

        <v-divider class="my-5" />

        <v-sheet
          rounded
          color="orange darken-4"
          class="pb-3"
        >
          <v-subheader>
            {{ $t('freespins_tip1') }}
          </v-subheader>

          <ul class="text-left text-caption opacity-5">
            <li>{{ $t('freespins_tip2') }}</li>
            <li>{{ $t('freespins_tip3') }}</li>
            <li>{{ $t('freespins_tip4') }}</li>
            <li>{{ $t('freespins_tip5') }}</li>
            <li>{{ $t('freespins_tip6') }}</li>
            <li>{{ $t('freespins_tip7') }}</li>
            <li>{{ $t('freespins_tip8') }}</li>
            <li class="font-weight-bold">
              {{ $t('freespins_tip9') }}
            </li>
          </ul>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
    today_available: 0,
    total_available: 0,
  }),
  watch:{

  },
  created() {

  },
  mounted() {
    this.freespin()
  },
  methods: {
    freespin() {
      let paramObj = {
        member_id: this.getStorage('member_id')
      }
      this.$server.freespin(paramObj).then((response) => {
        if(response.code==200){
          this.today_available = response.data.today_available
          this.total_available = response.data.total_available
        }else{
          
        }
      })
    },
    enterfree(game_id) {
      if(this.today_available<1){
        this.$snackbar.warning(this.$t('nofreespins_tip'))
        return false
      }
      this.$router.push({
        name: 'game_id',
        params:{
          id: game_id,
          free: 1
        }
      })
    }
  }
}
</script>